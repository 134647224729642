import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../components/seo"
import Headings from "../components/headings"
import Hero from "../components/hero"
import { Helmet } from "react-helmet"
import CtaSlider from "../components/cta-slider"
import ArticleSlider from "../components/article-slider"

const FaqsPage = () => {
  const { strapiFaq } = useStaticQuery(graphql`
    query {
      strapiFaq {
        hero {
          ...StrapiHero
        }
        headings {
          title
          description
        }
        articleSlider {
          articles {
            ...StrapiArticles
          }
        }
        callToAction {
          ...StrapiCallToAction
        }
        seo {
          ...StrapiSeo
          metaSocial {
            socialNetwork
            title
            description
          }
          structuredData {
            mainEntity {
              _type
              acceptedAnswer {
                _type
                text
              }
            }
          }
        }
      }
    }
  `)

  //console.log("strapiFaq: ", strapiFaq)

  const { hero, headings, articleSlider, callToAction, seo } = strapiFaq

  const structuredData = seo.structuredData

  const imageUrl = `${seo.metaImage.localFile.publicURL}`

  const articles = articleSlider.articles
  //console.log("strapiFaq - articleSlider articles: ", articles)


  const callToActionSections = callToAction.call_to_action_sections
  //console.log("strapiFaq callToActionSections: ", callToActionSections)

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            structuredData,
          })}
        </script>
      </Helmet>
      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={imageUrl}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />
      <Hero hero={hero} />

      <section className="uk-section uk-section-muted uk-padding-remove">
        <div className="uk-container uk-margin-small-bottom">
          <Headings title={headings.title} description={headings.description} />
          <div className="uk-margin-medium-bottom" style={{ marginTop: "20px"}}>
            <div uk-grid="true" className="uk-grid-match uk-child-width-1-2@s">
              <div className="pooche-card">
                <div className="uk-card uk-card-default uk-card-body animated fadeIn uk-border-rounded">
                  <div
                    className="uk-card-badge uk-label"
                    style={{
                      background: "linear-gradient(45deg, #78866b, #a7a4a4)",
                      fontWeight: "600" 
                    }}
                  >
                    FAQ
                  </div>
                  <div className="uk-margin-small-top">
                    <h4
                      className="green-grad-faqs uk-margin-right uk-margin-remove "
                      style={{ fontWeight: "700" }}
                    >
                      <span>Explore Facilities Management FAQs</span>
                    </h4> 
                  </div>
                  
                  <p>
                  Uncover the key to optimal property performance! Dive into JWRs Facilities Management 
                  FAQs to explore tailored solutions for commercial and domestic spaces. Elevate your 
                  property's maintenance, security, and energy management. Ready to redefine your 
                  property's potential? Click below to read our FAQs and embark on a journey of excellence.
                  </p>
                  {/* This is an anchor toggling the modal */}

                  <button
                    className="uk-button uk-button-default uk-button-small uk-margin-small-bottom buttonHover"
                    data-uk-icon="arrow-right"
                    style={{ borderColor: "#687169", color: "#687169", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                    uk-toggle="target: #modal-facility-management-servies"
                  >
                    <span className="uk-margin-small-right">
                      Read More
                    </span>
                  </button>

                  {/* This is the modal */}
                  <div
                    id="modal-facility-management-servies"
                    className="uk-modal-container"
                    uk-modal="true"
                  >
                    <div className="uk-modal-dialog">
                      <button
                        className="uk-modal-close-default"
                        type="button"
                        aria-label="Close"
                        uk-close="true"
                      ></button>

                      <div className="uk-modal-header">
                        <h4
                          className="green-grad-faqs uk-margin-right uk-margin-remove"
                          style={{ fontWeight: "700" }}
                        >
                          <span>Facilities Management FAQs</span>
                        </h4>   
                      </div>

                      <div className="uk-modal-body" uk-overflow-auto="true">
                        <h5 className="uk-margin-remove">What services does JWRs offer in facilities management?</h5>
                        <p>JWRs specializes in a comprehensive range of facilities management services, including 
                          maintenance, security, cleaning, and energy management, for both domestic and commercial 
                          properties.
                        </p>

                        <h5 className="uk-margin-remove">How can facilities management benefit my commercial property?</h5>
                        <p>
                          Effective facilities management ensures a well-maintained, secure, and efficient environment, 
                          enhancing productivity, employee satisfaction, and overall operational performance in commercial 
                          spaces.
                        </p>

                        <h5 className="uk-margin-remove">Are your facilities management services tailored for domestic properties as well?</h5>
                        <p>
                          Yes, we offer customized facilities management solutions for domestic properties, addressing 
                          maintenance, safety, and comfort to create a harmonious living environment.
                        </p>

                        <h5 className="uk-margin-remove">What makes JWRs stand out in the facilities management industry?</h5>
                        <p>
                          JWRs stands out through a commitment to excellence, cutting-edge technology utilization, and a 
                          personalized approach, ensuring our clients receive top-tier services that align with their unique needs.
                        </p>

                        <h5 className="uk-margin-remove">How can I request facilities management services from JWRs?</h5>
                        <p>
                          To request our services, simply visit our "Contact Us" page, fill out the form, and our team will promptly 
                          get in touch to discuss your requirements and tailor a solution that suits your property.
                        </p>
                      </div>

                      <div className="uk-modal-footer uk-text-right">
                        <button
                          className="uk-button uk-button-default uk-button-small uk-margin-small-bottom buttonHover uk-animation-fade uk-modal-close"
                          data-uk-icon="arrow-right"
                          style={{ borderColor: "#687169", color: "#687169", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                        >
                          <span className="uk-margin-small-right">
                            Close
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* End of modal */}
                </div>
              </div>
              <div className="pooche-card">
                <div className="uk-card uk-card-default uk-card-body animated fadeIn uk-border-rounded">
                  <div
                    className="uk-card-badge uk-label"
                    style={{
                      background: "linear-gradient(45deg, #78866b, #a7a4a4)",
                      fontWeight: "600" 
                    }}
                  >
                    FAQ
                  </div>
                  <div className="uk-margin-small-top">
                    <h4
                      className="green-grad-faqs uk-margin-right uk-margin-remove "
                      style={{ fontWeight: "700" }}
                    >
                      <span>Explore Property Management FAQs</span>
                    </h4> 
                  </div>
                  <p>
                    Unlock the secrets to seamless property ownership! Delve into JWRs Property Management 
                    FAQs for insights into our services tailored for commercial and residential properties. 
                    Discover how we enhance property value and ensure tenant satisfaction. Ready to entrust 
                    your assets to a dedicated team? Click below to read our FAQs and elevate your property experience.
                  </p>
                  {/* This is an anchor toggling the modal */}
                  <button
                    className="uk-button uk-button-default uk-button-small uk-margin-small-bottom buttonHover"
                    data-uk-icon="arrow-right"
                    style={{ borderColor: "#687169", color: "#687169", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                    uk-toggle="target: #modal-client-property-services"
                  >
                    <span className="uk-margin-small-right">
                      Read More
                    </span>
                  </button>

                  {/* This is the modal */}
                  <div
                    id="modal-client-property-services"
                    className="uk-modal-container"
                    uk-modal="true"
                  >
                    <div className="uk-modal-dialog">
                      <button
                        className="uk-modal-close-default"
                        type="button"
                        aria-label="Close"
                        uk-close="true"
                      ></button>

                      <div className="uk-modal-header">
                        <h3
                          style={{ color: "#617F7F", fontWeight: "700" }}
                          className=""
                        >
                          Property Management Faqs?
                        </h3>
                      </div>

                      <div className="uk-modal-body" uk-overflow-auto="true">
                      <h5 className="uk-margin-remove">What does property management entail for residential properties?</h5>
                        <p>
                          Residential property management by JWRs includes tenant relations, property maintenance, rent 
                          collection, and ensuring a smooth overall experience for both property owners and tenants.
                        </p>

                        <h5 className="uk-margin-remove">How can property management services benefit commercial property owners?</h5>
                        <p>
                          Our commercial property management services encompass lease administration, maintenance, and tenant 
                          satisfaction, ultimately optimising property value and ensuring seamless operations.
                        </p>

                        <h5 className="uk-margin-remove">What makes JWRs a reliable choice for property management?</h5>
                        <p>
                          JWRs combines industry expertise, cutting-edge technology, and a client-focused approach to deliver 
                          reliable property management services, ensuring the protection and enhancement of property value.
                        </p>

                        <h5 className="uk-margin-remove">How can property owners stay updated on the performance of their assets under JWRs management?</h5>
                        <p>
                        We provide transparent and regular communication to property owners, including detailed reports and updates on 
                        property performance. Our online portal <span style={{ fontWeight: "600"}}>(Coming Soon)</span> also allows easy access to essential information anytime.
                        </p>
                        <p>
                          Feel free to incorporate these FAQs and answers into your FAQ page, providing valuable information for visitors 
                          seeking clarity on your facilities and property management services.
                        </p>
                      </div>

                      <div className="uk-modal-footer uk-text-right">
                      <button
                          className="uk-button uk-button-default uk-button-small uk-margin-small-bottom buttonHover uk-animation-fade uk-modal-close"
                          data-uk-icon="arrow-right"
                          style={{ borderColor: "#687169", color: "#687169", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                        >
                          <span className="uk-margin-small-right">
                            Close
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* End of modal */}
                </div>
              </div>
            </div>
          </div>
        </div>        
      </section>

      <section className="uk-section uk-section-default uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
          <Headings
            title="Featured Articles"
            description="Checkout some of our articles from James Walter Raymond Ltd, where you will be able to find an abundant wealth of information, regarding facilities and property management, and how JWR can help you in your quest for knowledge, due diligence or necessary services you may require."
          />
          <div className="uk-container" style={{ marginTop: "20px"}}>
            <ArticleSlider articles={articles} />
          </div>
        </div>
      </section>

      <section className="uk-section uk-section-muted uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
          <Headings
              title={callToAction.title}
              description={callToAction.description}
            />
          <div className="uk-container uk-margin">
            <CtaSlider callToActionSections={callToActionSections} />
          </div>
        </div>
      </section>
    </>
  )
}

export default FaqsPage
